import React from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid2';
import { LeftPlace } from '../../components/Wrappers/Wrappers';
import DoctorInfoUserAvatarCard from './DoctorInfoUserAvatarCard';
import DoctorInfoSlots from './DoctorInfoSlots';
import DoctorInfoUserCalendar from './DoctorInfoUserCalendar';
import DoctorInfoAbout from './DoctorInfoAbout';
import { useVisitState } from '../../context/VisitContext';

const useStyles = makeStyles((theme) => ({
  marginBottom: { marginBottom: theme.spacing(2) },
  marginTop: { marginTop: theme.spacing(2) },
  content: {
    [theme.breakpoints.down('md')]: {
      minWidth: 320,
    },
    height: '100%',
    paddingBottom: theme.spacing(4),
  },

  leftConent: {
    maxWidth: 362,
    minWidth: 342,

    [theme.breakpoints.down('md')]: {
      maxWidth: 'auto',
    },
    textAlign: 'center',
    justifySelf: 'center',

    paddingBottom: theme.spacing(1),
    boxSizing: 'border-box',
  },
  rightConent: {
    height: '100%',
    width: 'auto',
    minWidth: 'auto',
    marginLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
      marginTop: theme.spacing(2),
    },
  },

  leftPlace: {
    maxWidth: 330,
    alignSelf: 'center',
    position: 'fixed',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'auto',
      position: 'initial',
    },
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function DoctorInfo({
  modalDoc,
  toggleModalCreateVisit,
  setModalDoc,
}) {
  const classes = useStyles();
  const {
    state: {
      visitData: { doctor, visitKind },
    },
  } = useVisitState();

  return (
    <Grid
      container
      spacing={0}
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.content}
    >
      <Grid size={{ xs: 12, sm: 4 }} className={classes.leftConent}>
        <LeftPlace className={classes.leftPlace}>
          <DoctorInfoUserAvatarCard
            doctor={doctor}
            visitKind={visitKind}
          />
          <DoctorInfoUserCalendar modalDoc={modalDoc} />
        </LeftPlace>
      </Grid>
      <Grid
        size={{ xs: 12, sm: 'grow' }}
        className={classes.rightConent}
      >
        <DoctorInfoSlots
          toggleModalCreateVisit={toggleModalCreateVisit}
          setModalDoc={setModalDoc}
        />

        <DoctorInfoAbout />
      </Grid>
    </Grid>
  );
}
