import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Alert,
  Box,
  Fade,
  LinearProgress,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useUserStateDispatch } from '../../context/UserContext';
import { esiaAuthCode, loginUserData } from '../../actions/user';
import { useLanguageValue } from '../../context/LanguageContext';
import isEmpty from '../../helpers';
import Loading from '../../components/Loading';
import { useTranslation } from 'react-i18next';
import ShowResponse from '../../components/FormComponents/ShowResponse';
const useStyles = makeStyles((theme) => ({
  errorMessage: {
    marginBottom: theme.spacing(4),
    width: '100%',
  },
}));

const Esia = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const error_description = searchParams.get('error_description');

  const {
    languageState: { language },
  } = useLanguageValue();
  const navigate = useNavigate();

  const {
    userDispatch,
    userState: { appInfo, serverResponse, isLoaded },
  } = useUserStateDispatch();

  useEffect(() => {
    if (code && !isEmpty(appInfo)) {
      esiaAuthCode({
        dispatch: userDispatch,
        code,
        lang: language,
        navigate,
        isAnonymousChildrenEnabled:
          appInfo?.isAnonymousChildrenEnabled,
      });
    }
  }, [language, appInfo?.isAnonymousChildrenEnabled]);

  const authToken = localStorage.getItem('authToken');

  return (
    <>
      <Typography variant="h1" sx={{ mb: 4 }}>
        {!authToken
          ? 'Попытка авторизации через Госуслуги'
          : 'Вы авторизованы через Госуслуги'}
      </Typography>

      {!isLoaded && <Loading />}

      {isLoaded && !error_description && (
        <Box sx={{ width: '100%', mb: 3 }}>
          <LinearProgress />
        </Box>
      )}
      <ShowResponse
        serverResponse={
          serverResponse || error_description || t('SIGN.IN_ERRORS')
        }
      />
    </>
  );
};
export default Esia;
