import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField as Input, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useUserStateDispatch } from '../../context/UserContext';
import MuiUIPicker from '../MUIDatePicker';
import dayjs from 'dayjs';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function InsuranceCompanyForm({
  values,
  errors,
  handleChange,
  handleAnyChange,
  isDisable,
  required = [],
}) {
  const { t } = useTranslation();

  const {
    userState: { appInfo },
  } = useUserStateDispatch();

  return (
    (appInfo.showInsuranceDMS || appInfo.showInsuranceOMS) && (
      <>
        <Grid size={12}>
          <Typography variant="h5" align="center">
            {t('PROFILE.INSURANCE_COMPANY')}
          </Typography>
        </Grid>
        {appInfo.showInsuranceDMS && (
          <Grid size={12}>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, sm: 6 }}>
                <Input
                  name="dmsName"
                  variant="outlined"
                  value={values?.dmsName || ''}
                  onChange={handleChange}
                  label={t('COMPONENT.FORM_DMS_NAME')}
                  type="text"
                  fullWidth
                  inputProps={{ maxLength: 255 }}
                  required={required.includes('dmsName')}
                  disabled={isDisable('dmsName')}
                  error={errors?.dmsName != null}
                  helperText={
                    errors?.dmsName != null && errors?.dmsName
                  }
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <Input
                  name="dmsNumber"
                  variant="outlined"
                  value={values?.dmsNumber || ''}
                  onChange={handleChange}
                  label={t('COMPONENT.FORM_DMS_NUMBER')}
                  type="text"
                  inputProps={{ maxLength: 50 }}
                  fullWidth
                  required={required.includes('dmsNumber')}
                  disabled={isDisable('dmsNumber')}
                  error={errors?.dmsNumber != null}
                  helperText={
                    errors?.dmsNumber != null && errors?.dmsNumber
                  }
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <MuiUIPicker
                  value={dayjs(values.dmsEndDate)}
                  handleChange={(dmsEndDate) => {
                    dmsEndDate =
                      dmsEndDate != null ? dayjs(dmsEndDate) : null;
                    handleAnyChange({ dmsEndDate });
                  }}
                  disablePast
                  label={t('COMPONENT.FORM_DMS_END_DATE')}
                  errorText={errors?.dmsEndDate}
                  name="date-picker-dmsEndDate"
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {appInfo.showInsuranceOMS && (
          <Grid size={12}>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, sm: 6 }}>
                <Input
                  name="omsName"
                  variant="outlined"
                  value={values?.omsName || ''}
                  onChange={handleChange}
                  label={t('COMPONENT.FORM_OMS_NAME')}
                  type="text"
                  fullWidth
                  inputProps={{ maxLength: 255 }}
                  required={required.includes('omsName')}
                  disabled={isDisable('omsName')}
                  error={errors?.omsName != null}
                  helperText={
                    errors?.omsName != null && errors?.omsName
                  }
                />
              </Grid>
              <Grid item size={{ xs: 12, sm: 6 }}>
                <Input
                  name="omsNumber"
                  variant="outlined"
                  value={values?.omsNumber || ''}
                  onChange={handleChange}
                  label={t('COMPONENT.FORM_OMS_NUMBER')}
                  type="number"
                  fullWidth
                  required={required.includes('omsNumber')}
                  disabled={isDisable('omsNumber')}
                  error={errors?.omsNumber != null}
                  helperText={
                    errors?.omsNumber != null && errors?.omsNumber
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    )
  );
}

export default InsuranceCompanyForm;
