import React, { useEffect } from 'react';

import {
  createUser,
  singInUpConfirmationCode,
  loginUser,
} from '../../actions/user';

import useForm from '../../hooks/useForm';
import { validate as validateSignUp } from './validationSignUp';
import validateConfirmSignUp from './validationConfirmSignUp';

import { dateToHL7 } from '../../helpers/dateFormat';
import SignUpForm from '../../components/Forms/SignUpForm';
import { useUserStateDispatch } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function SignUp({ valsTab, setValsTab }) {
  const navigate = useNavigate();
  const {
    userState: {
      user: { lang },
      appInfo,
      serverResponse,
    },
    userDispatch,
  } = useUserStateDispatch();
  const signUp = () =>
    createUser(
      userDispatch,
      {
        ...values,
        birthDate: values.birthDate
          ? dateToHL7(values.birthDate.toDate())
          : null,
      },

      appInfo.countryCode,
    );
  const login = () =>
    loginUser(
      userDispatch,
      values?.login,
      values?.password,
      navigate,
      setValsTab,
      valsTab,
      lang,
      appInfo.isAnonymousChildrenEnabled,
    );
  const confirmSignUp = () =>
    singInUpConfirmationCode(userDispatch, {
      ...values,
      birthDate: values.birthDate
        ? dateToHL7(values.birthDate.toDate())
        : null,
    });

  const isConfirmForm =
    serverResponse &&
    (serverResponse?.action === 'CONFIRM_CODE_SENT' ||
      serverResponse?.action === 'WRONG_CONFIRMATION_CODE');
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
    handlePhoneChange,
  } = useForm(
    isConfirmForm ? confirmSignUp : signUp,
    isConfirmForm ? validateConfirmSignUp : validateSignUp,
    appInfo,
  );

  useEffect(() => {
    if (serverResponse?.action === 'MAY_LOGIN') login();
    // eslint-disable-next-line
  }, [serverResponse?.action]);

  const handleDateChange = (birthDate) => {
    birthDate = birthDate != null ? dayjs(birthDate) : null;

    const vals = {
      ...values,
      birthDate,
    };

    setValues(vals);
    setErrors(validateSignUp(vals, appInfo));
  };

  const handleChangeGender = (event) => {
    const vals = {
      ...values,
      gender: event.target.value,
    };
    setValues(vals);
    setErrors(validateSignUp(vals, appInfo));
  };

  const setActiveTabId = (activeTabId) => {
    userDispatch({
      type: 'LOADED',
    });
    setValsTab({
      ...valsTab,
      activeTabId,
    });
  };

  return (
    <SignUpForm
      values={values}
      handleChange={handleChange}
      setValues={setValues}
      setErrors={setErrors}
      errors={errors}
      validate={validateSignUp}
      serverResponse={serverResponse}
      handleSubmit={handleSubmit}
      handleDateChange={handleDateChange}
      handleChangeGender={handleChangeGender}
      handlePhoneChange={handlePhoneChange}
      setActiveTabId={setActiveTabId}
    />
  );
}

export default SignUp;
