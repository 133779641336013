import React, { Fragment, useEffect } from 'react';
import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import Grid from '@mui/material/Grid2';

//import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { TextField as Input } from '@mui/material';
import { useVisitState } from '../../context/VisitContext';
import {
  addVisit,
  //addAnonymousVisit,
  sendRequestEmailToClinic,
  customCheck,
} from '../../actions/visit';

import { useUserStateDispatch } from '../../context/UserContext';

import { makeStyles } from '@mui/styles';
import validate from './validationCreateVisit';
import useForm from '../../hooks/useForm';

import { useTranslation } from 'react-i18next';
import isEmpty from '../../helpers';
import config from '../../config';
//import ProfileForm from '../../components/Forms/ProfileForm';
import FIOandPhoneForm from '../../components/Forms/FIOandPhoneForm';
import cn from 'classnames';
import {
  HL7ToDateToTime,
  HL7ToDateToSrtDate,
  formatDate,
  dateToHL7,
} from '../../helpers/dateFormat';
import { cleanPhoneValue } from '../../helpers/utils';

import { RANGE_AGE_GROUP } from '../../helpers/validators';
import Loading from '../../components/Loading';
import ChildForm from '../../components/Forms/ChildForm';
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles((theme) => ({
  buttonSubmit: {
    height: theme.spacing(6),
    minWidth: theme.spacing(30),
  },

  itemBorder: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    height: 1,
    padding: '0px !important;',
    margin: 0,
  },

  textAlignRight: {
    marginLeft: 'auto',
    textAlign: 'end',
  },

  avatarProfile: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  rowValue: {
    fontSize: 16,
    fontWeight: 500,
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const FIOandPhoneFormRow = (props) => {
  return (
    <Grid size={12}>
      <FIOandPhoneForm {...props} />
    </Grid>
  );
};

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const TitleRow = ({ item }) => {
  const classes = useStyles();
  return (
    <>
      <Grid size={4}>
        <Typography variant="subtitle2">{item.title}</Typography>
      </Grid>
      <Grid size={6.5}>
        <Typography variant="body1" className={classes.rowValue}>
          {item.value}
        </Typography>
      </Grid>
      <Grid
        size={1.5}
        className={cn(classes.itemInfo, classes.textAlignRight)}
      >
        {item.img != null && (
          <Avatar
            alt={item.value}
            src={item.img}
            classes={{ root: classes.avatarProfile }}
          />
        )}
      </Grid>
      <Grid size={12} className={classes.itemBorder} />
    </>
  );
};

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const AlertRow = ({ item }) => {
  return (
    <Grid size={12}>
      {item?.loading ? (
        <Alert severity="info">
          <Loading msg={item.value} variant="h6" isLinear />
        </Alert>
      ) : (
        <Alert severity="warning">
          <Typography variant="h6">{item.value}</Typography>
        </Alert>
      )}
    </Grid>
  );
};

const AlertInfoRow = ({ item }) => {
  return (
    <Grid size={12} mb={2}>
      <Alert
        severity="success"
        icon={<InfoIcon sx={{ color: '#FA9907' }} />}
      >
        <Typography variant="h6">{item.value}</Typography>
      </Alert>
    </Grid>
  );
};

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const PhoneRow = ({
  values,
  errors,
  setValues,
  setErrors,
  validate,
  appInfo,
  handlePhoneChange,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid size={4}>
        <Typography variant="subtitle2">
          {t('COMPONENT.FORM_PHONE')}
        </Typography>
      </Grid>
      <Grid size={8}>
        <Input
          name="phone"
          variant="standard"
          value={values.phone || ''}
          onChange={handlePhoneChange}
          //  disabled={values.oldEmail == null}

          margin="normal"
          type="text"
          fullWidth
          error={errors?.phone != null}
          helperText={errors?.phone != null && errors?.phone}
        />
      </Grid>
    </>
  );
};

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const CustomCheckRow = ({ values, handleСustomCheck }) => {
  const { t } = useTranslation();
  return (
    <Grid size={12}>
      <FormControlLabel
        control={
          <Checkbox
            name="notifyCustomCheck"
            checked={values.customCheck.checked}
            onChange={handleСustomCheck}
            color="primary"
            disabled={false}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
            inputProps={{
              'data-testid': 'notifyCustomCheck',
            }}
          />
        }
        label={
          <Typography variant="h6">
            {t('VISIT.AGREE_PAID_SERVICES')}
          </Typography>
        }
      />
    </Grid>
  );
};

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const ChildFormRow = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChange = (e, imChildRep) => {
    const vals = {
      ...props.values,
      imChildRep,
      childFirstName: null,
      childLastName: null,
      childMiddleName: null,
      childBirthDate: null,
    };
    if (!imChildRep) {
      delete vals.childFirstName;
      delete vals.childLastName;
      delete vals.childMiddleName;
      delete vals.childBirthDate;
    }

    props.setValues(vals);
    props.setErrors(props.validate(vals, props.appInfo));
  };

  return (
    <>
      <Grid size={12} sx={{ position: 'relative', top: -12 }}>
        <FormControlLabel
          control={
            <Checkbox
              name="imChildRep"
              checked={props.values?.imChildRep}
              onChange={handleChange}
              color="primary"
              sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
            />
          }
          label={
            <Typography variant="body1">
              {t('VISIT.IM_CHILD_REPRESENTATIVE')}
            </Typography>
          }
        />
        {props.values.imChildRep && (
          <>
            <Typography variant="h6" mt={3}>
              {t('VISIT.ENTER_CHILD_DATA')}
            </Typography>
            <ChildForm {...props} />
            <Box mb={3}></Box>
          </>
        )}
      </Grid>
      <Grid size={12} className={classes.itemBorder} />
    </>
  );
};

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const VisitReasonRow = ({ values, errors, handleChange }) => {
  const { t } = useTranslation();
  return (
    <Grid size={12} mt={2}>
      <Input
        name="reason"
        variant="outlined"
        value={values.reason || ''}
        onChange={handleChange}
        label={t('VISIT.FORM_REASON')}
        type="text"
        multiline
        rows={4}
        fullWidth
        required
        inputProps={{
          maxLength: 600,
          'data-testid': 'reason',
        }}
        error={errors?.reason != null}
        helperText={errors?.reason != null && errors?.reason}
      />
    </Grid>
  );
};

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function CreateVisit({ toggleModalCreateVisit }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    setState,
    state: { visitData, selectedDate },
  } = useVisitState();

  const {
    visitDate,
    timeSlots,
    doctor,
    visitKind,
    clinic,
    patientDirectionId,
    isOnline,
  } = visitData;

  const hasSlots = !isEmpty(timeSlots);

  // const navigate = useNavigate();
  const {
    userState: {
      user,
      isAuthenticatedDoctor,
      appInfo,
      mmkLinkedList,
    },
  } = useUserStateDispatch();

  const forPet = appInfo?.uiType === 'petClinic';

  const defineVisitCustomCheck = () => {
    if (!hasSlots) return false;

    if (
      appInfo.visitCustomCheck === 'withMmk' &&
      user.isAnonymous === false
    ) {
      return true;
    }

    if (
      appInfo.visitCustomCheck === 'withoutMmk' &&
      user.isAnonymous === true
    )
      return true;
    if (appInfo.visitCustomCheck === 'all') return true;
    return false;
  };

  const visitCustomCheck = defineVisitCustomCheck();

  const isSettingChildRepresentativeEnabled =
    user.mmkId !== 'parent'
      ? false
      : appInfo.isSettingChildRepresentativeEnabled &&
        (doctor?.ageGroup === 'Child' || doctor?.ageGroup === 'Any');

  const showAddVisitComment = appInfo.showAddVisitComment && hasSlots;

  const appInfoChange = {
    ...appInfo,
    isSettingChildRepresentativeEnabled,
    visitCustomCheck,
    showAddVisitComment,
  };

  const createVisit = () => {
    if (!hasSlots) {
      let params = {
        setState,
        lastName: values.lastName,
        firstName: values.firstName,
        middleName: values.middleName,
        phone: cleanPhoneValue(values.phone),
        plSubjId: doctor?.plSubjId,
        visitDate,
        clinicId: doctor?.clinicId,
        //reason: values.reason,
      };
      if (appInfoChange.showAddVisitComment && values.reason) {
        params = { ...params, reason: values.reason };
      }
      sendRequestEmailToClinic(params);
    } else {
      let comment = '';
      if (appInfoChange.showInsuranceDMS) {
        const dateFormat =
          user.lang != null && user.lang === 'ru'
            ? 'DD.MM.YYYY'
            : 'MM/DD/YYYY';
        comment = `${t('COMPONENT.FORM_DMS_COMMENT')}: ${
          user.dmsName
        } ${user.dmsNumber}
${t('COMPONENT.FORM_DMS_COMMENT_UNTILL')}: ${
          user.dmsEndDate
            ? HL7ToDateToSrtDate(
                user.dmsEndDate,
                user.lang,
                dateFormat,
              )
            : ''
        }`;
      }
      if (visitCustomCheck && values.customCheck.checked) {
        comment += '\n';
        comment += t('VISIT.AGREE_PAID_SERVICES');
      }
      if (values.imChildRep) {
        comment += `\n\n  ----------- ${t(
          'VISIT.IM_CHILD_REPRESENTATIVE',
        )} ----------- \n\n`;

        comment += values.childLastName;
        comment += '\n';
        comment += values.childFirstName;
        comment += '\n';
        comment += values.childMiddleName;
        comment += '\n';
        comment += formatDate(
          values.childBirthDate.toDate(),
          user.lang,
        );

        comment += '\n';
      }
      if (appInfoChange.showAddVisitComment && values.reason) {
        comment += '\n';
        comment += values.reason;
      }
      addVisit({
        setState,
        doctor,
        mmkId: user.mmkId,
        visitKindId: visitKind?.id,
        visitDate,
        startDate: dateToHL7(selectedDate.toDate()),
        contactPhone: cleanPhoneValue(values.phone),
        patientDirectionId,
        isOnline,
        comment,
      });
    }

    toggleModalCreateVisit(visitData);
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
    handlePhoneChange,
  } = useForm(createVisit, validate, appInfoChange);

  let fioPatient = `${user.lastName || ''} ${user.firstName || ''} ${
    user.middleName ?? ''
  }`;

  const setCustomCheck = (val) => {
    const vals = {
      ...values,
      customCheck: { ...values.customCheck, ...val },
    };
    setValues(vals);
    setErrors(validate(vals, appInfoChange));
  };
  const handleСustomCheck = (e, value) => {
    const vals = {
      ...values,
      customCheck: {
        ...values.customCheck,
        checked: value,
      },
    };

    setValues(vals);
    setErrors(validate(vals, appInfoChange));
  };

  useEffect(() => {
    if (isAuthenticatedDoctor) {
      setValues({
        email: '',
        firstName: '',
        lastName: '',
        middleName: '',
        gender: '',
        phone: '',
        birthDate: '',
        mmkId: user.mmkId,
        fioPatient,
      });
    } else {
      const vals = {
        mmkId: user.mmkId,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        middleName: user.middleName,
        gender: user.gender,
        phone: user.phone || user.phoneParent,
        birthDate: user.birthDate,
        needPhone: user.isAnonymous || !hasSlots,
        isMmkLinkedList: !isEmpty(mmkLinkedList),
        ageGroup: doctor?.ageGroup,
        customCheck: { status: null, checked: false, msgText: '' },
        imChildRep: false,
      };
      setValues(vals);
      setErrors(validate(vals, appInfoChange));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (visitCustomCheck && values.mmkId != null) {
      setTimeout(() => {
        customCheck({
          setCustomCheck,
          clinicId: doctor?.clinicId,
          plSubjId: doctor?.plSubjId,
          plExamId: visitKind?.id,
          fmClinkPatientsId: null,
          param1: user.dmsNumber,
          param2: user.dmsEndDate,
          param3: user.omsNumber,
          param4: visitDate,
          mmkId: user.mmkId,
        });
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.mmkId]);

  const fioDoctor =
    doctor?.lastName != null || doctor?.firstName != null
      ? `${doctor?.lastName ?? ''} ${doctor?.firstName ?? ''} ${
          doctor?.middleName ?? ''
        }`
      : doctor?.plSubjName;
  const imgDoctor =
    doctor?.photoUrl != null
      ? `${config.baseURLApi}${doctor?.photoUrl}&appCode=${config.APP_CODE}`
      : null;

  const imgPatient = `data:image/jpeg;base64,${user.photo}`;

  const ageGroupTextValue = () => {
    if (doctor?.ageGroup === 'Adult')
      return t('VISIT.AGE_GROUP_ADULT', { age: RANGE_AGE_GROUP });
    if (doctor?.ageGroup === 'Child')
      return t('VISIT.AGE_GROUP_CHILD', { age: RANGE_AGE_GROUP });
    if (doctor?.ageGroup === 'Any') return t('VISIT.AGE_GROUP_ANY');
  };

  let gridInfo = [];
  /** ---------------------------------- beforeExamDescription -------------------------- */
  if (visitKind?.beforeExamDescription != null) {
    gridInfo = [
      ...gridInfo,
      {
        alertInfo: true,
        value: visitKind.beforeExamDescription,
      },
    ];
  }
  /** ---------------------------------- items, ChildFormRow Rows -------------------------- */
  if (hasSlots) {
    gridInfo = [
      ...gridInfo,
      {
        title: t('VISIT.CR_PATIENT'),
        value: fioPatient,
        img: imgPatient,
      },
    ];
    if (appInfoChange.isSettingChildRepresentativeEnabled) {
      gridInfo = [
        ...gridInfo,
        {
          childForm: true,
        },
      ];
    }
    const titleSpecializationName =
      appInfo?.usePlExGrWebSpecializations &&
      visitKind?.specializationName
        ? visitKind?.specializationName
        : doctor.specializationInfo;

    gridInfo = [
      ...gridInfo,

      {
        title: t('VISIT.CR_DOCTOR'),
        value: fioDoctor,
        img: imgDoctor,
      },
      {
        title: t('VISIT.CR_SPEC'),
        value: titleSpecializationName,
      },
      {
        title: t('VISIT.CR_VISITKIND'),
        value: visitKind?.name,
      },
      {
        title: t('VISIT.CR_DATE'),
        value: `${HL7ToDateToSrtDate(
          visitDate,
          user.lang,
        )} ${HL7ToDateToTime(visitDate)}`,
      },
      {
        title: t('VISIT.CR_ADDRESS'),
        value: clinic?.address,
      },
    ];
    if (!forPet) {
      gridInfo = [
        ...gridInfo,
        {
          title: t('VISIT.AGE_GROUP'),
          value: ageGroupTextValue(),
        },
      ];
    }
  }
  /** ---------------------------------- end items, ChildFormRow Rows -------------------------- */

  /** ---------------------------------- FIOandPhoneFormRow -------------------------- */
  if (!hasSlots) {
    gridInfo = [
      ...gridInfo,
      {
        fioPhoneForm: true,
      },
    ];
  }
  /** ---------------------------------- FIOandPhoneFormRow -------------------------- */
  /** ---------------------------------- age goup -------------------------- */

  if (errors.ageGroup != null) {
    gridInfo = [
      ...gridInfo,
      {
        alert: true,
        value: errors.ageGroup,
      },
    ];
  }
  /** ---------------------------------- end age goup -------------------------- */
  /** ---------------------------------- Alert custom check -------------------------- */
  if (visitCustomCheck && values.customCheck?.status === null) {
    gridInfo = [
      ...gridInfo,
      {
        alert: true,
        value: t('VISIT.WAITING_THE_CUSTOM_CHECK'),
        loading: true,
      },
    ];
  } else if (visitCustomCheck && errors.customCheck != null) {
    gridInfo = [
      ...gridInfo,
      {
        alert: true,
        value: errors.customCheck,
      },
    ];
  }
  /** ---------------------------------- end Alert custom check -------------------------- */
  /** ---------------------------------- PhoneRow -------------------------- */
  if (
    hasSlots &&
    ((hasSlots && user.phone == null) || user.phone === '') &&
    user.isAnonymous
  ) {
    gridInfo = [
      ...gridInfo,
      {
        phone: true,
      },
    ];
  }
  /** ---------------------------------- end PhoneRow -------------------------- */
  /** ---------------------------------- CustomCheckRow -------------------------- */
  if (
    hasSlots &&
    values.customCheck?.status === 2 &&
    !user.isAnonymous
  ) {
    gridInfo = [
      ...gridInfo,
      {
        customCheck: true,
      },
    ];
  }
  /** ---------------------------------- end CustomCheckRow -------------------------- */

  /** ---------------------------------- VisitReasonRow -------------------------- */
  if (
    (visitCustomCheck &&
      values.customCheck?.status != null &&
      appInfoChange.showAddVisitComment) ||
    (!visitCustomCheck && appInfoChange.showAddVisitComment)
  ) {
    gridInfo = [
      ...gridInfo,
      {
        reason: true,
      },
    ];
  }
  /** ---------------------------------- end VisitReasonRow -------------------------- */

  const disableButton = isEmpty(visitDate) || !isEmpty(errors);

  return (
    <>
      <Typography variant="h5" style={{ textAlign: 'center' }}>
        {t('MAINMENU.APPOINTMENT')}
      </Typography>
      <Grid container spacing={1} mx={4} mt={4}>
        {gridInfo.map((item, inx) => (
          <Fragment key={inx}>
            {item.title != null ? (
              <TitleRow item={item} />
            ) : item.fioPhoneForm ? (
              <FIOandPhoneFormRow
                values={values}
                errors={errors}
                handleChange={handleChange}
                setValues={setValues}
                setErrors={setErrors}
                validate={validate}
                appInfo={appInfoChange}
                handlePhoneChange={handlePhoneChange}
              />
            ) : item.alert ? (
              <AlertRow item={item} />
            ) : item.alertInfo ? (
              <AlertInfoRow item={item} />
            ) : item.childForm ? (
              <ChildFormRow
                values={values}
                errors={errors}
                handleChange={handleChange}
                setValues={setValues}
                setErrors={setErrors}
                validate={validate}
                appInfo={appInfoChange}
              />
            ) : item.phone ? (
              <PhoneRow
                values={values}
                errors={errors}
                setValues={setValues}
                setErrors={setErrors}
                validate={validate}
                appInfo={appInfoChange}
                handlePhoneChange={handlePhoneChange}
              />
            ) : item.customCheck ? (
              <CustomCheckRow
                values={values}
                handleСustomCheck={handleСustomCheck}
              />
            ) : item.reason ? (
              <VisitReasonRow
                values={values}
                errors={errors}
                handleChange={handleChange}
              />
            ) : null}
          </Fragment>
        ))}

        {/* {isAuthenticatedDoctor && user.isAnonymous && (
          <ProfileForm
            isDisable={() => false}
            values={values}
            errors={errors}
            handleChange={handleChange}
            setValues={setValues}
            setErrors={() =>
              setErrors(validate(values, appInfoChange))
            }
            required={[
              'lastName',
              'firstName',
              'middleName',
              'phone',
            ]}
            handlePhoneChange={handlePhoneChange}
          />
        )} */}

        <Grid size={12} align="center" my={4}>
          <Button
            className={classes.buttonSubmit}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={disableButton}
          >
            {hasSlots
              ? t('COMPONENT.BUT_CONFIRM_APPONTMENT')
              : t('COMPONENT.BUT_SEND_EMAIL_DOCTOR')}
          </Button>
        </Grid>
        <Grid size={12} align="center">
          <Button
            onClick={() => toggleModalCreateVisit(visitData)}
            color="secondary"
          >
            {t('COMPONENT.BUT_CLOSE')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
