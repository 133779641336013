import React, { Fragment, useCallback, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
//import { Typography } from "../../components/Wrappers";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import cn from 'classnames';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {
  HL7ToDateToTime,
  HL7ToDateToSrtDate,
} from '../../helpers/dateFormat';
import isEmpty from '../../helpers';
import config from '../../config';
import { useMmkState } from '../../context/MmkContext';
import { downloadFile, getMmkRecords } from '../../actions/mmk';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Button, Tooltip } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  borderRadiusRight: {
    borderRadius: '0 6px 6px 0',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0 0 6px 6px',
    },
  },
  borderRadiusLeft: {
    borderRadius: '6px 0 0 6px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '6px 6px 0 0',
    },
  },
  ifMobileThenHide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  item: {
    backgroundColor: theme.palette.bgLight.one,
    padding: theme.spacing(2),
  },
  itemHeight100: {
    height: '100%',
  },
  itemLast: {
    textAlign: 'right',
  },
  itemGep: {
    width: 2,
    height: 30,
    backgroundColor: theme.palette.primary.light,
    borderRadius: 1,
  },

  LocationOnIcon: {
    top: 5,
    position: 'relative',
    color: theme.palette.primary.light,
  },
  marginBottom: { marginBottom: theme.spacing(1) },
  Accordion: {
    border: 0,
    backgroundColor: 'inherit',
    boxShadow: 'none',
    padding: 0,
    '&::before': {
      display: 'none',
    },
    '& .MuiAccordionSummary-root': {
      minHeight: 22,
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      color: theme.palette.primary.light,
      fontWeight: 500,
    },
    '& .MuiAccordionDetails-root ': {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      display: 'block',
    },
    '& .MuiIconButton-root': {
      padding: 0,
      color: theme.palette.primary.light,
    },
  },
  fileBox: {
    backgroundColor: theme.palette.bgLight.two,
    margin: theme.spacing(0.5),
    padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
    boxshadow: theme.palette.shadows.card,
    borderRadius: theme.spacing(1),
  },
  fileBoxOpacity: {
    opacity: 0.4,
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

export default function MmkItem({
  item,
  mmkId,
  clinics,
  lang,
  authToken,
  toggleModal,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { setState } = useMmkState();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (id) => (event, isExpanded) => {
    setExpanded(isExpanded ? `panel${id}` : false);
    if (isExpanded)
      getMmkRecords({
        setState,
        id: item.id,
        clinicId: item.clinicId,
        doctorId: item.doctor.id,
        mmkId,
      });
  };
  const getUrl = useCallback((fileId) => {
    let url = `${config.baseURLApi}mmk/file/?`;
    const params = {
      clinicId: item.clinicId,
      fileId,
    };
    url += Object.entries(params)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');

    url += `&token=${authToken}`;
    return url;
  });

  const filename = useCallback((it) => {
    if (it.name.includes(it.extension)) return it.name;
    return `${it.name}${it.extension}`;
  });

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.marginBottom}
    >
      <Grid size={{ xs: 12, sm: 2 }}>
        <Box
          classes={{
            root: classes.borderRadiusLeft,
          }}
          className={cn(classes.item, classes.itemHeight100)}
          justifyContent="center"
        >
          <Typography variant="h4" align="center">
            {HL7ToDateToSrtDate(item.dateTime, lang, 'DD')}
          </Typography>
          <Typography align="center" variant="subtitle2">
            {HL7ToDateToSrtDate(item.dateTime, lang, 'MMM')}
          </Typography>
          <Typography variant="body2" align="center">
            {HL7ToDateToTime(item.dateTime)}
          </Typography>
        </Box>
      </Grid>

      <Grid className={classes.ifMobileThenHide}>
        <Grid
          container
          className={classes.itemHeight100}
          justifyContent="center"
          alignItems="center"
        >
          <Grid className={classes.itemGep}></Grid>
        </Grid>
      </Grid>
      <Grid className={classes.item} size={{ xs: 12, sm: 4 }}>
        <Typography variant="h6">{item?.recordType?.name}</Typography>
        {item.doctor != null && (
          <Accordion
            defaultExpanded={false}
            classes={{
              root: classes.Accordion,
            }}
            expanded={expanded === `panel${item.id}`}
            onChange={handleChange(item.id)}
          >
            <AccordionSummary
              classes={{
                root: classes.Accordion,
              }}
              expandIcon={<ExpandMoreIcon />}
            >
              {t('MMK.RESULTS')}
            </AccordionSummary>
            <AccordionDetails>
              {!item.records?.isLoaded && (
                <CircularProgress size={26} />
              )}
              {item.records?.isLoaded &&
                item.records?.responseRecordsError != null && (
                  <Alert severity="error">
                    <Typography variant="h6" component="p">
                      {item.records?.responseRecordsError}
                    </Typography>
                  </Alert>
                )}

              {item.records?.isLoaded &&
                !isEmpty(item.records?.data) &&
                !isEmpty(item.records?.data?.files) &&
                item.records.data.files.map((it) => {
                  it = {
                    ...it,
                    extension: it.extension.toLowerCase(),
                    //name: it.extension.toLowerCase(),
                  };

                  const url = getUrl(it.id);
                  //console.log('it -- ', it);
                  //console.log('filename', filename(it));
                  //console.log('item', item);
                  return (
                    <Box
                      key={it.id}
                      className={classNames(
                        classes.fileBox,
                        it.fileExists === false &&
                          classes.fileBoxOpacity,
                      )}
                    >
                      {[
                        '.png',
                        '.jpeg',
                        '.jpg',
                        '.gif',
                        '.tif',
                        '.bmp',
                      ].includes(it.extension) && (
                        <Tooltip title={it.name}>
                          <Button
                            color="primary"
                            startIcon={<ImageIcon />}
                            onClick={() =>
                              toggleModal({
                                url,
                                title: it.name,
                                type: it.extension,
                              })
                            }
                          >
                            {t('MMK.PREVIEW')}
                          </Button>
                        </Tooltip>
                      )}

                      {it.extension === '.pdf' && (
                        <Tooltip title={it.name}>
                          <Button
                            color="primary"
                            startIcon={<PictureAsPdfIcon />}
                            onClick={() =>
                              toggleModal({
                                url,
                                title: it.name,
                                type: it.extension,
                              })
                            }
                          >
                            {t('MMK.PREVIEW')}
                          </Button>
                        </Tooltip>
                      )}
                      <Tooltip title={it.name}>
                        <Button
                          color="primary"
                          startIcon={<CloudDownloadIcon />}
                          //component="a"
                          onClick={() =>
                            downloadFile(
                              setState,
                              url,
                              filename(it),
                              it.extension,
                            )
                          }
                        >
                          {t('MMK.DOWNLOAD')}
                        </Button>
                      </Tooltip>
                    </Box>
                  );
                })}

              {item.records?.isLoaded &&
                isEmpty(item.records?.data?.files) && (
                  <Alert severity="warning">
                    <Typography variant="body2">
                      {t('MMK.NO_MMK_RECORDS')}
                    </Typography>
                  </Alert>
                )}
            </AccordionDetails>
          </Accordion>
        )}
      </Grid>
      <Grid className={classes.item} size={{ xs: 12, sm: 3 }}>
        {!isEmpty(item.doctor) && (
          <>
            <Typography variant="h6">
              {item.doctor.lastName} {item.doctor.firstName}{' '}
              {item.doctor.middleName ?? ''}
            </Typography>
            <Typography variant="body2">
              {item.specializationName ||
                item.doctor?.specializationInfo}
            </Typography>
          </>
        )}
      </Grid>
      <Grid
        className={cn(classes.item, classes.borderRadiusRight)}
        size={{ xs: 12, sm: 'grow' }}
      >
        <Typography variant="h6">
          {clinics.find((it) => it.id === item.clinicId)?.name}
        </Typography>
        <Typography variant="subtitle2" component="p">
          <LocationOnIcon className={classes.LocationOnIcon} />
          {clinics.find((it) => it.id === item.clinicId)?.address}
        </Typography>
      </Grid>
    </Grid>
  );
}
