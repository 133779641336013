import React, { useEffect } from 'react';
import {
  CircularProgress,
  Collapse as Fade,
  TextField as Input,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
// styles
import useStyles from './styles';

// context
import { useUserStateDispatch } from '../../context/UserContext';

import {
  updateUserInfo,
  changeEmailConfirmationCode,
  changePhoneConfirmationCode,
} from '../../actions/user';

//components
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ProfileForm from '../../components/Forms/ProfileForm';
import ProfileFormPets from '../../components/Forms/ProfileFormPets';

import ProfileDelete from './ProfileDelete';
//form func
import useForm from '../../hooks/useForm';
import { validate } from './validation';
import validationConfirm from './validationConfirm';
import { useTranslation } from 'react-i18next';
import isEmpty from '../../helpers';
import { dateToHL7 } from '../../helpers/dateFormat';
import { Alert } from '@mui/material';
import {
  cleanPhoneValue,
  formatPhoneString,
} from '../../helpers/utils';
import dayjs from 'dayjs';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function Profile() {
  const { t } = useTranslation();

  const classes = useStyles();
  const {
    userState: {
      user: {
        id,
        isAnonymous,
        mmkId,
        clinicId,
        email,
        firstName,
        gender,
        phone,
        lastName,
        middleName,
        birthDate,
        dmsName,
        dmsNumber,
        dmsEndDate,
        omsName,
        omsNumber,
      },
      isLoaded,
      serverResponse,
      user,
      alert,
      appInfo,
    },

    userDispatch,
  } = useUserStateDispatch();
  const phoneNumber = phone
    ? formatPhoneString(phone, appInfo.countryCode)
    : '';

  const submit = () => {
    if (
      values.confirmationCode !== '' &&
      values.oldEmail !== values.email &&
      values.email !== ''
    ) {
      changeEmailConfirmationCode(userDispatch, values, user);
    }
    if (
      values.confirmationCode !== '' &&
      values.oldPhone !== values.phone &&
      values.phone !== ''
    ) {
      changePhoneConfirmationCode(userDispatch, values, user);
    }
    if (values.confirmationCode === '') {
      let vals = {
        ...values,
        phone: cleanPhoneValue(values.phone),
        birthDate:
          values.birthDate != null
            ? dateToHL7(dayjs(values.birthDate).toDate())
            : null,
        dmsEndDate:
          values.dmsEndDate != null
            ? dateToHL7(dayjs(values.dmsEndDate).toDate())
            : null,
      };

      updateUserInfo(userDispatch, mmkId, clinicId, vals, user);
    }
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
    handlePhoneChange,
  } = useForm(
    submit,
    serverResponse?.action === 'CONFIRM_CODE_SENT'
      ? validationConfirm
      : validate,
    appInfo,
  );

  useEffect(() => {
    if (values.confirmationCode !== '')
      setValues({
        ...values,
        confirmationCode: '',
      });
    if (serverResponse?.action === 'OK')
      setValues({
        ...values,
        oldEmail: email,
        oldPhone: phoneNumber,
        confirmationCode: '',
      });
  }, [serverResponse?.action]);

  useEffect(() => {
    if (serverResponse == null) {
      setValues({
        id,
        email,
        oldEmail: email,
        oldPhone: phoneNumber,
        confirmationCode: '',
        firstName,
        lastName,
        middleName,
        gender,
        phone: phoneNumber,
        birthDate: birthDate ? dayjs(birthDate) : null,
        mmkId,
        dmsName,
        dmsNumber,
        dmsEndDate: dmsEndDate ? dayjs(dmsEndDate) : null,
        omsName,
        omsNumber,
      });
    }
  }, [mmkId]);
  const forPet = appInfo?.uiType === 'petClinic';

  const isDisable = (name) => {
    if (
      !isAnonymous &&
      [
        'lastName',
        'firstName',
        'middleName',
        'birthDate',
        'gender',
      ].includes(name)
    )
      return true;
    if (
      !isAnonymous &&
      mmkId !== 'parent' &&
      ['email', 'phone'].includes(name)
    )
      return true;

    if (forPet && ['email', 'phone'].includes(name)) return true;

    return false;
  };
  const isChild = !isAnonymous && mmkId !== 'parent';

  return (
    <>
      {alert != null && (
        <Dialog
          open={true}
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <Typography variant="body2">
              {t('COMPONENT.NEEDED_PHONE_FOR_APP')}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() =>
                userDispatch({
                  type: 'SET_USER',
                  payload: {
                    alert: null,
                  },
                })
              }
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Grid container spacing={4}>
        <Grid size={12}>
          <Fade in={serverResponse?.action != null}>
            <Alert
              severity={
                serverResponse?.action === 'OK' ||
                serverResponse?.action === 'PHOTO_DELETED' ||
                serverResponse?.action === 'PHOTO_UPLOAD_OK'
                  ? 'success'
                  : 'warning'
              }
            >
              <Typography variant="h6">
                {serverResponse?.action === 'OK'
                  ? t('COMPONENT.DATA_SAVED')
                  : serverResponse?.action === 'CODE_SENT_PHONE' &&
                    serverResponse?.phoneConfirmationCodeInfo
                      ?.deliveryMethod === 'Email'
                  ? t('SIGN.CH_CONFIRM_CODE_SENT_EMAIL')
                  : serverResponse?.action === 'CODE_SENT_PHONE' &&
                    serverResponse?.phoneConfirmationCodeInfo
                      ?.deliveryMethod === 'Sms'
                  ? t('SIGN.CH_CONFIRM_CODE_SENT_SMS')
                  : serverResponse?.action === 'CODE_SENT_PHONE' &&
                    serverResponse?.phoneConfirmationCodeInfo
                      ?.deliveryMethod === 'VoiceCall'
                  ? t('SIGN.CH_CONFIRM_CODE_SENT_VOICE')
                  : serverResponse?.action === 'CODE_SENT_EMAIL' &&
                    serverResponse?.phoneConfirmationCodeInfo
                      ?.deliveryMethod === 'Email'
                  ? t('SIGN.CH_CONFIRM_CODE_SENT_EMAIL')
                  : serverResponse?.action === 'CODE_SENT_EMAIL' &&
                    serverResponse?.phoneConfirmationCodeInfo
                      ?.deliveryMethod === 'Sms'
                  ? t('SIGN.CH_CONFIRM_CODE_SENT_SMS')
                  : serverResponse?.action === 'CODE_SENT_EMAIL' &&
                    serverResponse?.phoneConfirmationCodeInfo
                      ?.deliveryMethod === 'VoiceCall'
                  ? t('SIGN.CH_CONFIRM_CODE_SENT_VOICE')
                  : serverResponse?.action ===
                    'WRONG_CONFIRMATION_CODE'
                  ? t('SIGN.UP_CONFIRM_CODE_WRONG')
                  : serverResponse?.action === 'ACCOUNT_DELETED'
                  ? t('PROFILE.ACCOUNT_DELETED')
                  : serverResponse?.action === 'PHOTO_UPLOAD_OK'
                  ? t('PROFILE.PHOTO_UPLOAD_OK')
                  : serverResponse?.action === 'PHOTO_DELETED'
                  ? t('PROFILE.PHOTO_DELETED')
                  : serverResponse?.message}
              </Typography>
              {!forPet && (
                <Typography variant="body2">
                  {serverResponse?.action === 'CODE_SENT_PHONE'
                    ? t('SIGN.CH_CONFIRM_CODE_SENT_CHANGE_PHONE')
                    : !forPet &&
                      serverResponse?.action === 'CODE_SENT_EMAIL'
                    ? t('SIGN.CH_CONFIRM_CODE_SENT_CHANGE_EMAIL')
                    : ''}
                </Typography>
              )}
              {forPet &&
                (serverResponse?.action === 'CODE_SENT_PHONE' ||
                  serverResponse?.action === 'CODE_SENT_EMAIL') && (
                  <Typography variant="body2">
                    {t('SIGN.UP_NOT_VALID_CONFIRM_CODE')}
                  </Typography>
                )}
            </Alert>
          </Fade>
        </Grid>
        <Grid size={{ xs: 12, md: 8 }}>
          {serverResponse?.action === 'CODE_SENT_EMAIL' ||
          serverResponse?.action === 'CODE_SENT_PHONE' ||
          serverResponse?.action === 'WRONG_CONFIRMATION_CODE' ? (
            <Input
              name="confirmationCode"
              variant="outlined"
              value={values.confirmationCode}
              onChange={handleChange}
              margin="normal"
              label={t('COMPONENT.CONFIRM_CODE')}
              type="tel"
              fullWidth
              required
              error={errors?.confirmationCode != null}
              helperText={
                errors?.confirmationCode != null &&
                errors?.confirmationCode
              }
            />
          ) : (
            <>
              {isChild && (
                <Alert severity="info" style={{ marginBottom: 24 }}>
                  <Typography variant="body2">
                    {t('PROFILE.ALERT_CHILD_DATA')}
                  </Typography>
                </Alert>
              )}
              {forPet ? (
                <ProfileFormPets
                  isDisable={isDisable}
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  setValues={setValues}
                  setErrors={setErrors}
                  validate={validate}
                  mmkId={mmkId}
                  handlePhoneChange={handlePhoneChange}
                />
              ) : (
                <ProfileForm
                  isDisable={isDisable}
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  setValues={setValues}
                  setErrors={setErrors}
                  validate={validate}
                  mmkId={mmkId}
                  handlePhoneChange={handlePhoneChange}
                />
              )}
            </>
          )}
        </Grid>
        <Grid
          size={{
            xs: 12,
            md:
              appInfo.showInsuranceDMS || appInfo.showInsuranceOMS
                ? 4
                : 8,
          }}
          className={classes.buttonPlace}
        >
          {!isLoaded ? (
            <CircularProgress
              size={26}
              className={classes.loginLoader}
            />
          ) : (
            <Button
              disabled={isChild || !isEmpty(errors)}
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              fullWidth
              className={classes.saveButton}
            >
              {t('COMPONENT.BUTTON_SAVE')}
            </Button>
          )}
        </Grid>
      </Grid>
      {mmkId === 'parent' && !forPet && <ProfileDelete />}
    </>
  );
}

export default Profile;
